import { makeAutoObservable } from 'mobx'

export enum WarningCode {
  notDefined,
  notEnoughMoneyInfo, // не хватает денег
  betLessMinLimit, // ставка меньше лимита
  betMoreMaxLimit, // ставка больше лимита
}

export default class NotificationStore {
  warningCode: WarningCode = WarningCode.notDefined

  timerWarningCode: NodeJS.Timeout | undefined = undefined

  constructor() {
    makeAutoObservable(this, {
      timerWarningCode: false,
    })
  }

  get isWarningCode(): boolean {
    return [WarningCode.betLessMinLimit, WarningCode.betMoreMaxLimit, WarningCode.notEnoughMoneyInfo].includes(
      this.warningCode
    )
  }

  setWarningCode = (data: WarningCode): void => {
    this.warningCode = data

    if (this.timerWarningCode !== undefined) clearTimeout(this.timerWarningCode)

    this.timerWarningCode = setTimeout(this.clearWarningCode, 1000)
  }

  private clearWarningCode = (): void => {
    this.warningCode = WarningCode.notDefined
  }
}
