import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'
import { ColoredTxt, Txt } from './styles'
import { WarningCode } from '../../stores/Roulette/Notification.store'

const Informer = observer((): JSX.Element => {
  const { t } = useTranslation()
  const { rouletteStore } = useStore()
  const { timerStore, statusGameStore, notificationStore } = rouletteStore

  if (notificationStore.isWarningCode) {
    return (
      <Txt>
        <ColoredTxt color="red">
          {notificationStore.warningCode === WarningCode.notEnoughMoneyInfo && t('notEnoughMoneyInfo')}
          {notificationStore.warningCode === WarningCode.betMoreMaxLimit && t('betMoreMaxLimit')}
          {notificationStore.warningCode === WarningCode.betLessMinLimit && t('betLessMinLimit')}
        </ColoredTxt>
      </Txt>
    )
  }

  return (
    <Txt>
      &nbsp;
      {statusGameStore.isBetting && (
        <ColoredTxt color="green">
          {t('placeYourBets')} {timerStore.timer > 0 && timerStore.timer}
        </ColoredTxt>
      )}
      {statusGameStore.isSpinStatus && <ColoredTxt color="green">{t('noMoreBets')}</ColoredTxt>}
      {/* {game.isNewNumberStatus && <ColoredTxt>{game.lastBall}</ColoredTxt>} */}
      &nbsp;
    </Txt>
  )
})

export default Informer
